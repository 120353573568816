<template>
  <div class="fill-height pa-10">
    <div v-if="isLoading"> Загрузка... </div>
    <div v-if="error"> {{ error }} </div>
  </div>
</template>

<script>

const redirectUrl = '/start';

export default {
  name: 'SsoConnection',
  data () {
    return {
      error: null
    }
  },
  computed: {
    isLoading () {
      return this.$store.state.app.loading;
    },
    isAuthorized () {
			return this.$store.getters['user/isEducontUser'];
    }
  },
  mounted () {
    this.isAuthorized ?
      window.location = redirectUrl :
      this.externalSignup();
  },
  methods: {
    async externalSignup () {
			await this.$store.dispatch('app/toggleLoading', true);
			try {
				const { data } = await this.$store.dispatch('app/getSSOAuthUrl', redirectUrl);
				if (!data?.['auth-url']) {
					this.error = 'Ошибка на сервере. Попробуйте ещё раз позже.';
					return;
				}
				// Отправляем пользователя авторизоваться через Инополис
				window.location = data?.['auth-url'];
			} catch (e) {
				console.error(e);
				this.error = 'Ошибка на сервере. Попробуйте ещё раз позже.';
        await this.$store.dispatch('app/toggleLoading', false);
			}
		}
  }
}
</script>